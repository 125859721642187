/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 29, 2020 */
/*! See generator_config.txt for details */
/*! Customized and optimized by Codistica */

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-100-codistica.eot');
    src: url('lato-100-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-100-codistica.woff2') format('woff2'),
         url('lato-100-codistica.woff') format('woff'),
         url('lato-100-codistica.ttf') format('truetype'),
         url('lato-100-codistica.svg#latohairline') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-100i-codistica.eot');
    src: url('lato-100i-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-100i-codistica.woff2') format('woff2'),
         url('lato-100i-codistica.woff') format('woff'),
         url('lato-100i-codistica.ttf') format('truetype'),
         url('lato-100i-codistica.svg#latohairline_italic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-300-codistica.eot');
    src: url('lato-300-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-300-codistica.woff2') format('woff2'),
         url('lato-300-codistica.woff') format('woff'),
         url('lato-300-codistica.ttf') format('truetype'),
         url('lato-300-codistica.svg#latolight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-300i-codistica.eot');
    src: url('lato-300i-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-300i-codistica.woff2') format('woff2'),
         url('lato-300i-codistica.woff') format('woff'),
         url('lato-300i-codistica.ttf') format('truetype'),
         url('lato-300i-codistica.svg#latolight_italic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-400-codistica.eot');
    src: url('lato-400-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-400-codistica.woff2') format('woff2'),
         url('lato-400-codistica.woff') format('woff'),
         url('lato-400-codistica.ttf') format('truetype'),
         url('lato-400-codistica.svg#latoregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-400i-codistica.eot');
    src: url('lato-400i-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-400i-codistica.woff2') format('woff2'),
         url('lato-400i-codistica.woff') format('woff'),
         url('lato-400i-codistica.ttf') format('truetype'),
         url('lato-400i-codistica.svg#latoitalic') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-700-codistica.eot');
    src: url('lato-700-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-700-codistica.woff2') format('woff2'),
         url('lato-700-codistica.woff') format('woff'),
         url('lato-700-codistica.ttf') format('truetype'),
         url('lato-700-codistica.svg#latobold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-700i-codistica.eot');
    src: url('lato-700i-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-700i-codistica.woff2') format('woff2'),
         url('lato-700i-codistica.woff') format('woff'),
         url('lato-700i-codistica.ttf') format('truetype'),
         url('lato-700i-codistica.svg#latobold_italic') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-900-codistica.eot');
    src: url('lato-900-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-900-codistica.woff2') format('woff2'),
         url('lato-900-codistica.woff') format('woff'),
         url('lato-900-codistica.ttf') format('truetype'),
         url('lato-900-codistica.svg#latoblack') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'lato-codistica';
    src: url('lato-900i-codistica.eot');
    src: url('lato-900i-codistica.eot?#iefix') format('embedded-opentype'),
         url('lato-900i-codistica.woff2') format('woff2'),
         url('lato-900i-codistica.woff') format('woff'),
         url('lato-900i-codistica.ttf') format('truetype'),
         url('lato-900i-codistica.svg#latoblack_italic') format('svg');
    font-weight: 900;
    font-style: italic;
}
