.app {
  text-align: center;
}

.appHeader {
  background-color: rgba(0,0,0,0.15);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(12px + 1vmin);
  color: #ffffff;
  padding: 30px 0;
}

.oberkeyLogo {
  width: 15%;
  min-width: 100px;
  max-width: 120px;
  opacity: 0.8;
}

.titles {
  margin-top: 250px;
  line-height: 1.8;
}

.mainTitle {
  font-weight: bold;
  font-size: calc(20px + 2vmin);
}

.secondaryTitle {
  font-weight: normal;
  font-size: calc(8px + 2vmin);
}

.tertiaryTitle {
  font-weight: 300;
  font-size: calc(6px + 2vmin);
}

.socialBar {
  margin-top: 120px;
  white-space: nowrap;
  font-size: 0;
}

.socialBar > * {
  margin: 0 calc(10px + 2vw);
}

.contactUs {
  opacity: 0.5;
  transition: opacity 0.3s linear;
}

.contactUs:hover,
.contactUs:focus,
.contactUs:active,
.contactUs:focus-within {
  opacity: 1;
}
