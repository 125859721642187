:global * {
  box-sizing: border-box;
  font-family: lato-codistica, 'Lato', 'Futura', 'Helvetica', sans-serif;
  margin: 0;
  padding: 0;
}

:global body {
  margin: 0;
}

// BACKGROUND
:global body {
  background: url('./img/background.jpg') no-repeat center;
  background-size: cover;
}

// RESET
:global button {
  background-color: transparent;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

:global button:focus,
:global button::selection {
  outline: none;
  box-shadow: none;
}

:global a {
  outline: none;
  text-decoration: none;
  color: #ffffff;
}

:global a:focus {
  outline: none;
  box-shadow: none;
  opacity: 0.6;
}
